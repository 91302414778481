@import '@angular/material/theming';
@include mat-core();

$custom-palette: (
  50: #E6EDFF,
  100: #B3C4FF,
  200: #809BFF,
  300: #4D72FF,
  400: #335AFF,
  500: #244BE5, // Primary Color
  600: #1E43CC,
  700: #183BB3,
  800: #12329A,
  900: #0C2A80,
  A100: #B2C7FF,
  A200: #7F9FFF,
  A400: #4C76FF,
  A700: #335AFF,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
    )
);

$color_primary: $custom-palette;
$color_secondary: $mat-deep-purple;
$color_tertiary:  $mat-deep-orange;

$tamis-primary: mat-palette($color_primary);
$tamis-accent:  mat-palette($color_secondary);
$tamis-warn:    mat-palette($color_tertiary);
$tamis-theme: mat-light-theme($tamis-primary, $tamis-accent, $tamis-warn);
@include angular-material-theme($tamis-theme);

$color_background_surface: #FFF;
$color_background_main_container: #F1F0FF;
$color_background_filter_container: #E0E0E0;
$color_tamis_white: #F9F9F9;
$color_footer_background: #244BE5;
$white-outlined-active-bg: rgba(255, 255, 255, 0.14);