@import 'src/app/theme/colors.scss';

.mat-step-header {
    &:hover {
        background-color: $color_background_surface !important;
    }
    margin-bottom: 10px;
}

.mat-stepper-horizontal, .mat-stepper-vertical {
    background-color: $color_background_surface !important;
}
