/* You can add global styles to this file, and also import other style files */
@import "../src/app/theme/typograph.scss";
@import "../src/app/theme/input.scss";
@import "../src/app/theme/toolbar.scss";
@import "../src/app/theme/loader.scss";
@import "../src/app/theme/container.scss";
@import "src/app/theme/stepper.scss";
@import "src/app/theme/colors.scss";
@import "src/app/theme/table.scss";
@import "src/app/theme/button.scss";
@import "src/app/theme/radio-button.scss";

html,
body {
  /*CSS Reset*/
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: $global-font-size;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background-color: $color_background_main_container;
}

:root {
  h1,
  h2,
  h3,
  h4 {
    margin: 0;
    padding: 0;
  }

  table {
    width: 100%;
  }

  .mat-snack-bar-container {
    color: #FFF;

    .mat-simple-snackbar-action {
      height: 30px;
      margin-right: 12px;

      .mat-button-wrapper {
        display: none;
      }
    }
  }

  .Sucesso {
    background-color: #51a351;

    .mat-simple-snackbar-action {
      background: url('../src/assets/snack-icons/check_circle_white_30dp.svg') no-repeat;
    }
  }

  .Erro {
    background-color: #bd362f;

    .mat-simple-snackbar-action {
      background: url('../src/assets/snack-icons/cancel_white_30dp.svg') no-repeat;
    }
  }

  .Info {
    background-color: #f89406;

    .mat-simple-snackbar-action {
      background: url('../src/assets/snack-icons/info_white_30dp.svg') no-repeat;
    }
  }

  a {
    cursor: pointer;
    text-decoration: none;
  }

  .custom-tooltip {
    max-width: unset !important;
    word-break: break-word;
  }

  .mat-dialog-container {
    border-radius: 10px !important;
  }

  .ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }

  input[type='number'] {
      -moz-appearance: textfield; /* For Firefox */
  }
}

h4 {
  font-size: $heading-size !important;
  font-weight: bold;
}

span,
a,
p {
  font-size: $global-font-size;
}

.mat-slide-toggle-bar {
    position: relative;
    width: 36px;
    height: 20px !important;
    flex-shrink: 0;
    border-radius: 20px !important;
}

.mat-slide-toggle-thumb-container {
    width: 20px;
    height: 20px;
    top: 0px !important;
}

.mat-slide-toggle-thumb {
    height: 19px !important;
    width: 19px !important;
    border-radius: 50%;
    display: block;
    top: 0px !important;
}
