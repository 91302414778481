@import './colors.scss';

.custom-mini-fab .mat-icon {
  font-size: 15px;
}

.custom-mini-icon .mat-icon {
  font-size: 15px;
  height: 14px;
  width: 14px;
  margin-bottom: 2px;
}