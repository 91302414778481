@import '/src/app/theme/colors.scss';
$medium-device-width: 1200px;
$small-device-width: 1000px;
$mobile-device-width: 415px;
$small-mobile-device-width: 375px;

.page-container {
  width: calc(100vw - 300px);
  padding-inline: 150px;
  padding-top: 20px;
  padding-bottom: 50px;

  background-color: $color_background_main_container;

  .action-container {
    margin-inline: auto;
    min-width: 350px;
    max-width: 1300px;
  }

  .list-container {
    width: 100%;
    box-shadow: -10px 10px 20px rgba(0, 0, 0, 0.05);

    .filter-container {
      display: block;

      .filter-title {
        display: flex;
        align-items: center;

        i {
          font-size: 40px;
          margin: 0.5em;
        }

        .filter-title-message {
          text-align: center;
          font-size: 40px;
        }
      }

      .filter-input-fields {
        display: flex;
        flex-direction: column;
      }
    }

    .filter-actions-buttons {
      display: flex;
      justify-content: space-between;
    }

    .list-container-action-button {
      display: flex;
      justify-content: center;
      margin-top: 10px;
    }
  }

  @media (max-width: $medium-device-width) {
    width: calc(100vw - 200px);
    padding-inline: 100px;
    overflow: auto;
  }

  @media (max-width: $small-device-width) {
    width: calc(100vw - 100px);
    padding-inline: 50px;
    overflow: auto;
  }

  @media (max-width: $mobile-device-width) {
    width: calc(100vw - 14px);
    padding: 7px;

    .list-container-action-button {
      button {
        width: 100%;
      }
    }
  }

  @media (max-width: $small-mobile-device-width) {
    width: 100vw;
    padding-inline: 0;
  }
}

.large-container {
  width: calc(100vw - 100px);
  padding-inline: 50px;

  @media (max-width: $mobile-device-width) {
    width: calc(100vw - 14px);
    padding-inline: 7px;

    .list-container-action-button {
      button {
        width: 100%;
      }
    }
  }

  @media (max-width: $small-mobile-device-width) {
    width: 100vw;
    padding-inline: 0;
  }
}

.no-action-buttons {
  margin-block: 3.7rem;
}

.content-container {
  border-radius: 20px;
  border-color: #E0E3FF;
  background-color: $color_background_surface;
  height: auto;
  padding-bottom: 15px;
  margin-inline: auto;
  min-width: 350px;
  max-width: 1300px;
}

.mat-header-cell {
  font-size: 14px !important;
  font-weight: bold !important;
}

h1 {
  font-size: 3em;
  font-weight: 500;
  text-align: left;
}

.filter-container {
  padding: 10px;
  max-width: 100%;
}

.group-form {
  display: flex;
  flex-direction: column;

  @media (max-width: $small-device-width) {
    flex-direction: column;
  }
}