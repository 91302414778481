@import '/src/app/theme/colors.scss';

.table {
  border: 0 solid transparent;
}

.mat-row:nth-child(odd) {
  background-color: #f1f0ff !important;
}

.table-container {
  display: flex;
  overflow-x: auto;
  margin-top: 1em;
  flex-direction: column;
  width: 100%;

  @media (max-width: 430px) {
    .mat-paginator {
      .mat-paginator-container {
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        padding: 0;
      }
    }
  }
}

.no-table-items {
  display: flex;
  justify-content: center;
}
