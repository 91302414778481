.spinner-center {
  margin: auto
}

.spinner-center-block {
  margin: 10rem auto;
}

.spinner-center-block-5 {
  margin: 5rem auto;
}

.spinner-center-10rem {
  margin: 10rem;
}

.spinner-button {
  margin: 0 20px 10px 0;
}

.loader-screen {
  display: flex;
  height: calc(100vh - 180px);
}
