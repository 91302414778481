.form-input {
  max-width: 100%;

  .name-input {
    text-transform: capitalize;
  }

  .state-input {
    text-transform: uppercase;
  }

  @media (max-width: 540px) {
    min-width: 100px;
    max-width: 100%;
  }

  @media (max-width: 350px) {
    min-width: 70px;
    max-width: 100%;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}
